import React from 'react';
import fourbillys from './images/four-billys.png';

function ERC404() {
  return (
    <div className='error-div'>  
    <img src={fourbillys} className="billys-logo" alt="logo" />
    <br/><br/>   
     Page not found. In a twist of fate, it might have been minted as an exclusive ERC404 NFT. Try one of our other links.
    </div>    
)

  
}
export default ERC404;