import React from 'react';

function About() {
  return (
    <div>     
      <div className='about-text'>       
        <p>In the far reaches of the internet, beyond the memes of yesteryear and the forgotten email accounts, lies Chilltopia. Imagine a place where productivity is a myth and the concept of "hustle" is as alien as socks with sandals. That's Chilltopia for you—a digital utopia created by three slackers (affectionately known as the DUDES) who thought, "Why not?"</p>
        
        <h3>Meet the BILLYS</h3> 
        Too Cool for School, Too Laidback for Life. The inhabitants of this blissful realm? The BILLYS. Picture your coolest, most relaxed self, now multiply that by a hundred and add a sprinkle of "couldn't care less." Voilà, you've got a BILLY. These digital denizens are the embodiment of chill, each uniquely designed to make you question, "What am I doing with my life?"
        
        <h3>The DUDES</h3> 
        The Trio Who Made Laziness an Art Form. The architects of this slothful paradise, the DUDES, are three friends who discovered the secret to life (spoiler: it's doing as little as possible). They coded Chilltopia into existence one night, probably because they were too lazy to do anything else. Each BILLY is a digital middle finger to the rat race, a testament to the art of taking it easy.
        
        <h3>The Philosophy</h3> 
        If You're Trying, You're Trying Too Hard. Owning a BILLY isn't just a statement; it's a lifestyle choice. It's about embracing the joy of missing out (JOMO), celebrating mediocrity, and spreading the gospel of procrastination. The DUDES envision a community where BILLY holders unite over their mutual apathy and shared disdain for early mornings.
        
        <h3>The Grand Plan</h3> 
        Conquer the World, or Just Nap Instead. What's next for Chilltopia and its lethargic citizens? Whatever requires the least effort, obviously. The DUDES have plans—well, more like half-baked ideas—for exclusive events that you'll probably RSVP to and then forget about. And initiatives? Sure, they might start a few, if they ever get around to it.
        
        <p>In the end, becoming part of the BILLYS by DUDES saga means joining a movement that's all about celebrating the underachievement in all of us. So, grab your BILLY, kick back, and let the world hustle while you marvel at the art of doing absolutely nothing. Welcome to the good life, or at least, the good-enough life.</p>
      </div>
    </div>    
)

  
}
export default About;