import React from 'react';
import logo from './images/billyroom.png';

import './App.css';

function Home() {
    return (
    <div className="App">        
        <div className='page'>          
          <div>            
            <img src={logo} className="App-logo" alt="logo" />
          </div>
          <div className='home-text-div'>        
            The offbeat brigade hopping into the ethereum blockchain, jazzed up by the oh-so-rebellious ERC-404 token standard.
          </div>
         
        </div>      
    </div>
)
}

export default Home;