import { BrowserRouter, Route, Routes, Link, useLocation } from 'react-router-dom';
import Home from './Home';
import About from './About';
import ERC404 from './erc404';
import './App.css';
import { LiaExternalLinkAltSolid } from "react-icons/lia";  

function Navigation() {
  const location = useLocation();

  return (
    <div className='buttons'>
      <a href='https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=0x85A5cdab2A18064863f8e906e87c350a48dE0709'>Buy on Uniswap <LiaExternalLinkAltSolid /></a>
      <a href='https://opensea.io/collection/billysbydudes'>Buy on OpenSea <LiaExternalLinkAltSolid /></a>
      <a href='https://twitter.com/billysbydudes'>Twitter <LiaExternalLinkAltSolid /></a>
      <a href='https://t.me/billysbydudes'>Telegram <LiaExternalLinkAltSolid /></a>
      <a href='https://etherscan.io/address/0x85a5cdab2a18064863f8e906e87c350a48de0709'>Etherscan <LiaExternalLinkAltSolid /></a>

      {/* Exibe o link "Home" se não estiver na rota inicial "/" */}
      {location.pathname !== '/' ? (
        <Link to="/">Home</Link>
      ) : (
        <Link to="/about">About</Link>
      )}
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <header className="App-header">
        <div className='Text-logo'>
          Billys
        </div>
        <div className='Text-intro'>
          by dudes
        </div>        
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<ERC404 />} />
        </Routes>

        <Navigation />

        <div className='rights'>
          ®2024 Billys by Dudes.
        </div>
      </header>
    </BrowserRouter>
  );
}

console.log('Hey there, savvy internet wanderer! Looking to uncover the secrets of the universe, or just trying to break stuff?');
console.log('Either way, remember: The console is a dark and mysterious place, not for the faint of heart. Tread lightly, and may the force of the semicolon be with you.');
console.log('And a big shout-out to Pandora for unleashing the ERC-404 into the wild. Because, you know, what the world really needed was another reason to stare into the abyss of blockchain wonder. Hats off to you!');

export default App;
